import React, { useEffect } from "react";

const Content = () => {
  const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }

  useEffect(() => {
    addScript("https://app.termly.io/embed-policy.min.js")
  }, [])

  return (
    <>
      <div className="content-section bg-default-6 pt-lg-15 pt-10 pb-13 pb-lg-21">
        <div name="termly-embed" data-id="950d3388-63d4-46f8-b8da-8c932093ed2e" data-type="iframe"></div>
      </div>
    </>
  );
};

export default Content;