import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/terms/Content";

const Terms = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          isFluid: true,
        }}
      >
        <Content />
      </PageWrapper>
    </>
  );
};

export default Terms;